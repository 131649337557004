import React from 'react';
import '../StyleSheet/footer.css';

import Logo from "../Assist/home_page_logo.png"
import LinkedInIcon from "../Assist/linkedin.png"
import AtIcon from "../Assist/at.png"
import Facebook from "../Assist/facebook.png"
class Footer extends React.Component {
    render() {
        return (
            <div className='mainFlx'>
                <img src={Logo} className = 'logos' alt='Logo' />
                <div style={{width:'3px', backgroundColor:'white',height:'100%'}}>
                    <h1>.</h1>
                </div>
                <img src={LinkedInIcon}  className = 'icons' alt='LinkedIn'/>
                <img src={AtIcon} className = 'icons' alt='Web'/>
                <img src={Facebook}  className = 'icons' alt='Facebook'/>
                
            </div>
        )
    }
}
export default Footer;