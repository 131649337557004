import React from 'react';
import './StyleSheet/App.css';
import Counter from './Components/Counter';

// import HomePageLogo from "./Assist/home_page_logo.png";
// import AnimatedText from 'react-animated-text-content';
// import ProgrammingLanguages from './Assist/ProgrammingLanguages.png';
import Footer from './Components/Footer';
import InformationCard from './Components/InformationCards';
import Header from './Components/Header';
import ContactUs from './Components/ContactUs';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import contactllustration from './Assist/contactllustration.gif';

const goToTop = (position) => {
  window.scrollTo({
      top: position,
      behavior: 'smooth',
  });
};
function App() {
  return (
    <div>
      <div>
        <Header goToTop = {goToTop} />
        <div style={{ backgroundColor: '#ffffff', width: '95%', border: '1px', height: "100%", borderRadius: '13px', margin: 'auto', padding: '10px', marginTop: '-7%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', paddingBottom: '60px' }}>
          {/* Start -- Auto Counter */}
          <div>
            <Counter />
          </div>
          {/* End -- Auto Counter */}


          {/* Start Flip Card */}
          <div>
            <InformationCard />
          </div>
          {/* End -- Flip cards */}

          {/* Start -- Pref Text */}
          {/* <div className='childPrefDev'>
            <img src={ProgrammingLanguages} className="imgLang" />
            <div className='text_animated_pref'>
              <AnimatedText
                type="words" // animate words or chars
                animation={{
                  x: '200px',
                  y: '-20px',
                  scale: 0.5,
                  ease: 'ease-in-out',//ease-in-out
                }}
                animationType="wave"
                interval={0.06}
                duration={0.1}
                tag="p"
                className="animated-paragraph"
                includeWhiteSpaces
                threshold={0.1}
                rootMargin="20%">

                Semicolon Code is a software company that depends on the young working as freelancing with excellent experience and perfect knowledge of business, we will provide you with the best maintenance contract.


              </AnimatedText>
            </div>

          </div> */}
          {/* End -- Pref Text */}

         

        </div>

        <AnimationOnScroll animateIn="animate__bounceIn" style={{backgroundImage:`url(${contactllustration})`, width: '97%', border: '1px', height: "100%", borderRadius: '13px', backgroundRepeat:'no-repeat',backgroundSize:'cover', margin: 'auto', padding: '1px', marginTop: '2%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', paddingBottom: '60px'  }} >
        <div style={{textAlign:'center',height:'100%',margin:'auto'}}>
          {/* boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' */}
          {/* Start -- Contact Us */}
         
            <ContactUs />

          {/* End -- Contact Us */}
        </div>
        </AnimationOnScroll>

         {/* Start -- work tools */}
          <div className='footer'>
            <Footer />
          </div>
          {/* End -- work tools */}

      </div>


    </div>
  );
}

export default App;
