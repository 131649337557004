import React, { Component } from 'react';
import '../StyleSheet/counter.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Grid from '@mui/material/Grid';


class Counter extends Component {
  initState = {
    counter1: 1,
    counter2: 1,
    counter3: 1
  }
  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  componentDidMount() {
    if (this.state.counter1 <= 170) {
      this.interval = setInterval(() => this.setState({ counter1: this.state.counter1 + 1 }), 15);
    } else {
      this.setState({ counter1: this.state.counter1 })
    }

    if (this.state.counter2 <= 170) {
      this.interval = setInterval(() => this.setState({ counter2: this.state.counter2 + 1 }), 15);
    } else {
      this.setState({ counter2: this.state.counter2 })
    }

    if (this.state.counter3 <= 170) {
      this.interval = setInterval(() => this.setState({ counter3: this.state.counter3 + 1 }), 15);
    } else {
      this.setState({ counter3: this.state.counter3 })
    }
  }
  componentWillUnmount() {

    clearInterval(this.interval);
  }
  componentDidUpdate() {
    if (this.state.counter >= 170) {
      clearInterval(this.interval);
    }
  }
  render() {
    return (
      <div>
        <Grid spacing={{ xs: 1, md: 3 }} columns={{ xs: 1, sm: 12, md: 16 }} className='counterDiv'>
          <Grid xs={1} sm={1} md={1} lg={1} key={1} className='counterCyrcel'>
            <CircularProgressbar className='CircularProgressbarSty' value={this.state.counter1 <= 30 ? this.state.counter1 : 30} maxValue={100} text={this.state.counter1 <= 30 ? this.state.counter1 : "30%"} />
            <div style={{ textAlign: 'center', }}>
              <h4>30 % lower cost than companies</h4>
              <h6 style={{ maxWidth: '60%', textAlign: "center", alignSelf: "center", margin: "auto", color: "#ccced1", fontSize: 15 }}>because we are freelancers young entrepreneurs</h6>
            </div>
          </Grid>
          <Grid xs={1} sm={1} md={1} lg={1}key={2} className='counterCyrcel'>
            <CircularProgressbar className='CircularProgressbarSty' value={this.state.counter2 <= 16 ? this.state.counter2 : 16} maxValue={20} text={this.state.counter2 <= 16 ? this.state.counter2 : "+16"} />
            <div style={{ textAlign: 'center', }}>
              <h4>We serve more than 16 sectors</h4>
              <h6 style={{ maxWidth: '60%', textAlign: "center", alignSelf: "center", margin: "auto", color: "#ccced1", fontSize: 15 }}>Banks, Restaurants, warehouses, Pharmacies Startups</h6>
            </div>
          </Grid>
          <Grid xs={1} sm={1} md={1} lg={1} key={3} className='counterCyrcel'>
            <CircularProgressbar className='CircularProgressbarSty' value={this.state.counter3 <= 100 ? this.state.counter3 : 100} maxValue={100} text={this.state.counter3 <= 100 ? this.state.counter3 : "100%"} />
            <div style={{ textAlign: 'center', }}>
              <h4>100 % the best mintanence cotract</h4>
              <h6 style={{ maxWidth: '60%', textAlign: "center", alignSelf: "center", margin: "auto", color: "#ccced1", fontSize: 15 }}>we provide a lawyer who writes all contracts to us</h6>
            </div>
          </Grid>

        </Grid>
      </div>
    )

  }

}
export default Counter;