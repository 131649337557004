import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomePageLogo from "../Assist/home_page_logo.png";
import '../StyleSheet/header.css';
import HeaderBK from '../Assist/header_background_1.jpg' // header_background_1.jpg
const drawerWidth = 240;//mobile
const navItems = ['About', 'Contact'];

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  


  //for Mobile
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} style = {{backgroundColor:'black', height:'100%'}}>
      <Typography variant="h6" sx={{ my: 2 }} >
        <Box
        component="img"
        sx={{
          height: '100%',
          width: 170,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="Semicolon Code Logo."
        src={HomePageLogo}
      />
          </Typography>
      <div style={{height:'0.02%', width:'100%', backgroundColor:'white'}}></div>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }} style = {{color:"white"}}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div style = {{height:600,backgroundImage: `url(${HeaderBK})`,backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }}>
      <Box sx={{ display: 'flex' }} >
      <AppBar component="nav" style = {{width:'95%',height:60,marginTop:19,borderRadius:13,backgroundColor:"rgba(189,196,255,0.59)",justifyContent: 'center',marginLeft:'2.5%',marginRight:'2.5%'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h@"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
             <Box component="img"
             sx={{ height: '90%',width: 140,maxHeight: { xs: 233, md: 167 },maxWidth: { xs: 350, md: 250 }}}
             alt="SemicolonCode Logo."
             src={HomePageLogo}
             marginTop={'0.5%'}
             />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
            {navItems.map((item) => (
              <Button key={item} sx={{ color: 'white', fontFamily:'-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,Fira Sans, Droid Sans, Helvetica Neue, sans-serif' }} onClick = {()=>{item==='About'?props.goToTop(620):props.goToTop(1500)}}>
                {item}
              </Button>
            ))}
           {/* <Button variant="contained">Touch Us</Button> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography>
        </Typography>
      </Box>
      </Box>

      <div style={{margin: 'auto',width: '50%',padding: '10px',textAlign: 'center',color: 'currentcolor',}}>
        <div>
          <h1 style={{
                margin: '8px 0px 8px',
                fontFamily: "sans-serif",
                fontSize: '3rem',
                lineHeight: '1.25',
                fontWeight: '700',
                opacity: 1,
                textTransform: 'none',
                verticalAlign: 'unset',
                textDecoration: 'none',
                color: 'rgb(255, 255, 255)',
                letterSpacing: '-0.125px',
          }}>Semicolon Code </h1>
          <p style={{
            margin: '8px 0px 0px',
            fontFamily: 'sans-serif',
            fontSize: '1.45rem',
            fontWeight: '500',
            lineHeight: '1.900',
            textAlign: 'center',
            opacity: 1,
            textTransform: 'none',
            verticalAlign: 'unset',
            textDecoration: 'none',
            color: 'rgb(255, 255, 255)',
            letterSpacing: '-0.125px'
          }}>
          Build it. Code it. Deliver it. AI-powered for Tech Pioneers.
            </p>
          </div>
        </div>
    </div>
   
  );
}












// import React, { Component } from 'react';
// import '../StyleSheet/App.css';
// import '../StyleSheet/header.css';
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import HomePageLogo from "../Assist/home_page_logo.png";
// import 'react-circular-progressbar/dist/styles.css';
// import Grid from '@mui/material/Grid';


// class Header extends Component {
  
//  render() {
//     return (
//       <div className='counterDiv'>

//         {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}
//           <Toolbar className='toolbar_header'>
//             <Grid xs={4} sm={4} md={4} key={1} >
//               <div
//                 className="flx_home_page_logo">
//                 <img src={HomePageLogo} className="home_page_logo" />
//               </div>
//             </Grid>
//             <Grid xs={4} sm={4} md={4} key={2}>
//               <Typography variant="h6"
//                 sx={{ flexGrow: 1 }}
//                 component="div" className="header_top_tabs">
//                 <p className="header_top_tabs">About us</p>
//               </Typography>
//             </Grid>
//             <Grid xs={4} sm={4} md={4} key={3}>
//               <Typography variant="h6"
//                 sx={{ flexGrow: 1 }}
//                 component="div" className="header_top_tabs">
//                 <p className="header_top_tabs">Contact us</p>
//               </Typography>
//             </Grid>
//             <Grid xs={4} sm={4} md={4} key={4}>
//               <Typography variant="h6"
//                 sx={{ flexGrow: 1 }}
//                 component="div" >
//                 <p className="header_top_tabs">Our projects</p>
//               </Typography>
//             </Grid>

//           </Toolbar>
//         {/* </Grid> */}
//       </div>
//     )

//   }

// }
// export default Header;