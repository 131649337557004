import React, { useRef } from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import SemicolonLogo from '../Assist/semicolonlogo.jpg';
import Grid from '@mui/material/Grid';
import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2'
import { MdSupportAgent, MdModelTraining } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi'
export default function InformationCards() {
  const ref = useRef();
  return (
    <Grid container spacing={2} style={{ width: '90%', margin: 'auto', marginTop: "30px", alignContent: 'center', justifyContent: 'flexStart', alignItems: 'center' }}>
      <Grid xs={12} md={6} lg={3} >
        <Flippy
          flipOnHover={true} // default false
          flipOnClick={true} // default false
          flipDirection="horizontal" // horizontal or vertical
          ref={ref} // to use toggle method like ref.curret.toggle()
          // if you pass isFlipped prop component will be controlled component.
          // and other props, which will go to div
          style={{ width: '100%', height: '100%', marginTop: '50px', margin: 'auto' }} /// these are optional style, it is not necessary
        >
          <FrontSide style={{ borderRadius: '13px', border: '1px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', backgroundColor: 'white', }} >

            <img style={{ width: '100%', height: "80%" }} src={SemicolonLogo} alt='Logo'/>
            <h6 style={{ textAlign: 'center', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Why Semicolon Code ?</h6>
          </FrontSide>
          <BackSide style={{ textAlign: 'center',borderRadius: '13px', border: '1px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', backgroundColor: '#0e3467' }} >
            <p style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', color: 'white', fontSize: '23px',marginTop: '50%', height: '100%' }}>Guaranteed continuity of contracts, maintenance contracts, and work contracts.</p>
          </BackSide>
        </Flippy>
      </Grid>
      <Grid xs={12} md={6} lg={9} >
        <div style={{ width: '75%', margin: 'auto', height: '100%', marginTop: '20px' }}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '40%', margin: 'auto', height: '100%' }}>
              <div>
                <HiOutlineClipboardDocumentCheck color='#2a76d2' style={{ width: '40px', height: '40px' }} />
              </div>
              <div>
                <strong><span className='titleOfPoints'>Full Document</span></strong>
                <p className='subTitlePoints'>We provide you with a document that explains everything in the project and the code</p>
              </div>
            </div>
            <div style={{ width: '40%', margin: 'auto', height: '100%' }}>
              <div>
                <MdSupportAgent color='#2a76d2' style={{ width: '40px', height: '40px' }} />
              </div>
              <div>
                <strong> <span className='titleOfPoints'>Permanent Support</span></strong>
                <p className='subTitlePoints'>We provide you with permanent support for everything related to the project from start to finish</p>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '40%', margin: 'auto', height: '100%', marginTop: '20px' }}>
              <div>
                <GiReceiveMoney color='#2a76d2' style={{ width: '40px', height: '40px' }} />
              </div>
              <div>
                <strong><span className='titleOfPoints'>Save Time & Money</span></strong>
                <p className='subTitlePoints'>Start with us, we are able to do your work at low-cost and high quality.</p>
              </div>
            </div>
            <div style={{ width: '40%', margin: 'auto', height: '100%', marginTop: '20px' }}>
              <div>
                <MdModelTraining color='#2a76d2' style={{ width: '40px', height: '40px' }} />
              </div>
              <div>
                <strong><span className='titleOfPoints'>Training Session </span></strong>
                <p className='subTitlePoints'>A training session for the project for the team to cover all the features and functions based on the client's request.</p>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>


  )
}