import React from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            email: '',
            customerName: '',
            successAlertMessage: false,
            FalidAlertMessage: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    render() {
        return (
            <Grid container spacing={2} style={{ width: '90%', margin: 'auto', marginTop: "30px", alignContent: 'center', justifyContent: 'flexStart', alignItems: 'center' }}>
                {
                    this.state.FalidAlertMessage ?
                        <Box sx={{ width: '100%', marginBottom: '2%' }}>
                            <Alert
                                color="warning"
                                icon
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            this.setState({ FalidAlertMessage: false });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                Please Enter Invalid Email !!
                            </Alert>
                        </Box>
                        :
                        this.state.successAlertMessage ?
                            <Box sx={{ width: '100%', marginBottom: '2%' }}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                this.setState({ successAlertMessage: false });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    Your Request sent successfully
                                </Alert>
                            </Box> : ""
                }

                <Grid xs={12} md={12} lg={12} >
                    <div style={{ zIndex: 2, backgroundColor: '#ffffff', width: '65%', border: '1px', height: "100%", borderRadius: '13px', margin: 'auto', padding: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <div style={{
                            padding: '16px',
                            marginLeft: '16px',
                            marginRight: '16px',
                            marginTop: '-24px',
                            opacity: 1,
                            background: 'linear-gradient(195deg, rgb(26, 115, 232),rgb(14, 52, 103))',
                            color: 'rgb(52, 71, 103)',
                            borderRadius: '0.5rem',
                            boxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem'
                        }}>
                            <p style={{
                                margin: '0px',
                                fontFamily: "Roboto Slab, sans-serif",
                                fontSize: '1.875rem',
                                lineHeight: 1.375,
                                fontWeight: 700,
                                opacity: 1,
                                textTransform: 'none',
                                verticalAlign: 'unset',
                                textDecoration: 'none',
                                color: 'rgb(255, 255, 255)',
                                letterSpacing: '-0.125px'
                            }}>Contact Us</p>
                        </div>
                        <div style={{ color: 'rgb(123, 128, 154)', textAlign: 'center', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', lineHeight: 1.6, fontWeight: 300, margin: ' 0px 0px 24px', fontSize: '1rem' }}>
                            <p>tp compleate your project, with best contract contact us dirctly we will reply you within 5 mins, send your message to us and incloud your whatsapp number or make sure to write your email as well, we will reply to your email or/and whatsapp number</p>
                        </div>
                        <div style={{ color: 'rgb(123, 128, 154)', textAlign: 'center', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', lineHeight: 1.6, fontWeight: 300, margin: ' 0px 0px 24px', fontSize: '1rem' }}>
                            <TextField required value={this.state.customerName} style={{ marginLeft: '2%', marginRight: '2%', width: '46%', }} id="outlined-basic" label="Full Name" variant="outlined" onChange={(event) => this.setState({ customerName: event.target.value })} />
                            <TextField required value={this.state.email} style={{ marginLeft: '2%', marginRight: '2%', width: '46%', }} id="outlined-basic" label="Email" variant="outlined" onChange={(event) => this.setState({ email: event.target.value })} />
                        </div>
                        <div style={{ color: 'rgb(123, 128, 154)', width: '100%', textAlign: 'center', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', lineHeight: 1.6, fontWeight: 300, margin: ' 0px 0px 24px', fontSize: '1rem' }}>
                            <TextField
                                required
                                value={this.state.message}
                                id="outlined-textarea"
                                label="What can we help you?"
                                placeholder="Tell us about your new project, dream, idea, or anything you thinking about, We are here to support you!"
                                multiline
                                style={{ width: '96%', marginLeft: '2%', marginRight: '2%' }}
                                onChange={(event) => this.setState({ message: event.target.value })}
                            />
                        </div>
                        <div style={{ color: 'rgb(123, 128, 154)', width: '100%', textAlign: 'center', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', lineHeight: 1.6, fontWeight: 300, margin: ' 0px 0px 14px', fontSize: '1rem' }}>
                            <Button variant="contained" onClick={this.handleSubmit}>Send Message</Button>
                        </div>

                    </div>
                </Grid>
            </Grid>
        )
    }

    handleSubmit(event) {
        const templateId = 'template_s3gof85';
        var valirEmail = this.isValidEmail(this.state.email);
        if (valirEmail) {
            this.sendFeedback(
                templateId,
                {
                    'message_html': this.state.message,
                    'from_name': this.state.customerName,
                    'reply_to': this.state.email
                }, this);
            this.setState({
                message: '',
                email: '',
                customerName: '',
                successAlertMessage: true,
                FalidAlertMessage: false
            });
        }
        else {
            this.setState({ FalidAlertMessage: true, successAlertMessage: false });
        }
    }
    isValidEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }
    sendFeedback(templateId, variables, mself) {
        emailjs.send("service_owh8phx", templateId, {
            senderName: variables.from_name,
            message: variables.message_html,
            reply_to: variables.reply_to,
        }, "p4kMkteFX2E4NAkNI").then(res => {
            console.log('Email successfully sent!');
            mself.setState({ message: '' });
        })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
}
export default ContactUs;